import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import InvoiceMobile from "../invoice/InvoiceMobile"

import "../assets/dealminder.css"

function PmApproval(props) {
  const pgid = 'dd_76277FPD'
  const [lineItems, setLineItems] = useState([]);
  const [invHeader, setInvHeader] = useState({});
  const [refreshList, setRefreshList] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [vendorInfo, setVendorInfo] = useState({})

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const documentWrapperRef = React.useRef();
  const { iid } = useParams();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fetchVendor = async (id) => {
    const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
    setIsLoading(true);
    setErrorMsg('');
    try {
    const result = await axios(
      DD_API_HOST+'/ap_vendor?ref=' + id,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
      setPdfUrl('');
      setLineItems([]);
      setInvHeader({});
      setVendorInfo({});
    } else {
      setVendorInfo({vendor_id: result.data.header.id,
        company_id: result.data.header.company_id,
        vendor: result.data.header.vendor_entity_id,
        vendor_name: result.data.header.vendor_name,
        vendor_gl_account: result.data.header.gl_account,
      });
      setInvHeader({
        id: -1,
        vendor: result.data.header.vendor_entity_id,
        vendor_name: result.data.header.vendor_name,
        gl_account: result.data.header.gl_account
      })
      //console.log(pdfUrl);
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  function incrRefresh() {
    setRefreshList(refreshList+1);
  }

  useEffect(() => {
    // fetchInv(iid);
    fetchVendor(iid);
  }, []);

  return (
    <div>
    <Container fluid className="px-0">
      { errorMsg === '' ? (
        <span/>
      ) : (
        <Alert className="mb-0">
        <i className="fa fa-info mx-2"></i> {errorMsg}
        </Alert>
      )}
    </Container>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Create Invoice"
          subtitle="Account Payable Workflow"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="12" className="mb-4">
          <InvoiceMobile header={invHeader} lis={lineItems}
            pgid={pgid}
            cid={vendorInfo.company_id}
            incrRefresh={incrRefresh}/>
        </Col>
      </Row>
    </Container>
  </div>
  );  
}

export default PmApproval;
