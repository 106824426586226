import React, { useState } from "react";

import { Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import PageTitle from "../components/common/PageTitle";
import ImagePanel from "../components/user-profile-lite/ImagePanel";

function Logout() {
  let history = useHistory();
  
  // WG: Always sync this with saveToken
  sessionStorage.removeItem('dd_ap_token');
  sessionStorage.removeItem('ptid');
  sessionStorage.removeItem('mrid');

  history.push('/');
  history.go(0);
  return null;
}

export default Logout;
