import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";

function UserProfileLite() {
  const [userDetails, setUserDetails] = useState({});
  const [userFullName, setUserFullName] = useState('');

  useEffect(() => {
    let fn = sessionStorage.getItem('namae');
    let role = 'AP Entry';
    let rid = sessionStorage.getItem('mrid');
    if (rid == '1D') role = 'AP Manager';
    else if (rid == 'FD') role = 'Property Manager';

    let ud = {
      name: fn,
      avatar: require("./../images/avatars/0.jpg"),
      jobTitle: role,
      performanceReportTitle: "Workload",
      performanceReportValue: 74,
      metaTitle: "System Use",
      metaValue:
        "Per company policy, only authorized users can use this system."
    }
    setUserDetails(ud);
    setUserFullName(fn);
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      <Row>
        <Col lg="4">
          <UserDetails userDetails={userDetails}/>
        </Col>
        <Col lg="8">
          <UserAccountDetails fullname={userFullName} />
        </Col>
      </Row>
    </Container>
  );
}

export default UserProfileLite;
