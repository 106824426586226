export default function() {
  let nav_items = [];
  nav_items.push({
    title: "Dashboard",
    to: "/blog-overview",
    htmlBefore: '<i class="material-icons">edit</i>',
    htmlAfter: ""
    });
  nav_items.push({
      title: "AP Approval",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/ap-approval",
    });
  nav_items.push({
      title: "Manager Review",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/mgr-approval",
    });
  nav_items.push({
      title: "Supervisor Review",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/spvsr-approval",
    });
  nav_items.push({
      title: "Director Review",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/director-approval",
    });
  nav_items.push({
      title: "AP Entry",
      htmlBefore: '<i class="material-icons">view_module</i>',
      to: "/new-invoice",
    });
  // nav_items.push({
  //     title: "Walmart Deals",
  //     htmlBefore: '<i class="material-icons">view_module</i>',
  //     to: "/wm-deals",
  //   });
  // nav_items.push({
  //     title: "Components Overview",
  //     htmlBefore: '<i class="material-icons">view_module</i>',
  //     to: "/components-overview",
  //   });
  nav_items.push({
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile-lite",
    });
  nav_items.push({
      title: "User Management",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-management",
    });
  // nav_items.push({
  //     title: "Errors",
  //     htmlBefore: '<i class="material-icons">error</i>',
  //     to: "/errors",
  //   });
  return nav_items;
}
