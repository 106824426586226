import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import getSidebarNavItems from "../data/sidebar-nav-items";

let _store = {
  menuVisible: false,
  navItems: getSidebarNavItems()
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    // This is going to be called multiple times
    let token = sessionStorage.getItem('mrid');
    if (token && token == '15' && _store.navItems.length > 4) {
      // Follow DDAP-30
      let a = _store.navItems.splice(1,4);
      return _store.navItems.pop();
    }
    const mgrs = ['4D', 'DD', 'FD'];
    if (token && mgrs.indexOf(token) != -1 && _store.navItems.length > 4) {
      let a = _store.navItems.splice(1,1);
      console.log(_store.navItems);
      _store.navItems.splice(2,3);
      return _store.navItems.pop();
    }
    if (token && token == 'SD' && _store.navItems.length > 4) {
      // Follow DDAP-30
      let a = _store.navItems.splice(1,2);
      console.log(_store.navItems);
      _store.navItems.splice(2,2);
      return _store.navItems.pop();
    }
    if (token && token == 'BD' && _store.navItems.length > 4) {
      // Follow DDAP-30
      let a = _store.navItems.splice(1,3);
      console.log(_store.navItems);
      _store.navItems.splice(2,1);
      return _store.navItems.pop();
    }
    return _store.navItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
