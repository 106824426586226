import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import PageTitle from "../components/common/PageTitle";
import Forms from "../components/components-overview/Forms";
import FormValidation from "../components/components-overview/FormValidation";
import Sliders from "../components/components-overview/Sliders";
import ProgressBars from "../components/components-overview/ProgressBars";
import ButtonGroups from "../components/components-overview/ButtonGroups";
import InputGroups from "../components/components-overview/InputGroups";
import SeamlessInputGroups from "../components/components-overview/SeamlessInputGroups";
import CustomFileUpload from "../components/components-overview/CustomFileUpload";
import DropdownInputGroups from "../components/components-overview/DropdownInputGroups";
import CustomSelect from "../components/components-overview/CustomSelect";

import "../assets/dealminder.css"

function ApproveInvoices(props) {
  const [acctno, setAcctno] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [productName, setProductName] = useState('');
  const [propId, setPropId] = useState('');
  const [propAddr, setPropAddr] = useState('');
  const [amtdue, setAmtdue] = useState('');
  const [duedate, setDuedate] = useState('');
  const [acctglcode, setAcctglcode] = useState('');
  const [deals, setDeals] = useState([]);
  const [click, setClick] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fetchInvoices = async () => {
    setIsLoading(true);
    setErrorMsg('');
    try {
    const result = await axios(
      'https://yamatowg.nextcoder.com/ap_invoices?cid=334',
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
    } else {
      setDeals(result.data.invoices);
      setProductName(result.data.item_title);
      console.log(deals);  
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  const fetchPdf = async (reference) => {
    setIsLoading(true);
    setErrorMsg('');
    try {
    const result = await axios(
      'https://yamatowg.nextcoder.com/ap_pdf?ref=' + reference,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
      setPdfUrl('');
      setPropId('');
      setPropAddr('');
      setAcctno('');
      setAmtdue('');
      setDuedate('');
      setAcctglcode('');
    } else {
      setPdfUrl('https://yamatowg.nextcoder.com/invoice/'+result.data.pdf_url+'?tok=4234234');
      setPropId(result.data.pdf_fields.propid);
      setPropAddr(result.data.pdf_fields.propaddr);
      setAcctno(result.data.pdf_fields.acctno);
      setAmtdue(result.data.pdf_fields.amtdue);
      setDuedate(result.data.pdf_fields.duedate);
      setAcctglcode(result.data.pdf_fields.acctglcode);
      console.log(pdfUrl);
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  useEffect(() => {
    //fetchData();
    fetchInvoices();
  }, []);

  return (
    <div>
    <Container fluid className="px-0">
      { errorMsg === '' ? (
        <span/>
      ) : (
        <Alert className="mb-0">
        <i className="fa fa-info mx-2"></i> {errorMsg}
        </Alert>
      )}
    </Container>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Approve Invoices"
          subtitle="Account Payable Workflow"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="8" className="mb-4">
          <Card className="mb-4">
          <div>
            {/* The file should point to a URL */}
            {/*file="/inv1.pdf"*/}
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
          </div>
          </Card>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">{productName}</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
            {isLoading ? (
              <div className="is_loading_true">Searching ...</div>
            ) : (
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Date Received
                    </th>
                    <th scope="col" className="border-0">
                      Vendor
                    </th>
                    <th scope="col" className="border-0">
                      Invoice Type
                    </th>
                    <th scope="col" className="border-0">
                      Reference
                    </th>
                    <th scope="col" className="border-0">
                      Amount Due
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deals.map((item,i) => {
                    return (
                      <Fragment>
                        <tr onClick={() => {
                            fetchPdf(item.reference);
                          }} key={i}>
                          <td>{item.date_received}</td>
                          <td>{item.vendor}</td>
                          <td>{item.inv_type}</td>
                          <td>{item.reference}</td>
                          <td>{item.amtdue}</td>
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            )}
            </CardBody>
          </Card>

        </Col>

        <Col lg="4" className="mb-4">
        <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Invoice Fields</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Form className="wmdeals-form">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>Property</InputGroupText>
                        </InputGroupAddon>
                        <FormSelect id="prop"
                          value={propId}
                          onChange={event => setPropId(event.target.value)}
                        >
                          <option value={propId}>{propAddr}</option>
                        </FormSelect>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>Account #</InputGroupText>
                        </InputGroupAddon>
                        <FormInput id="acctno" placeholder="Account number" 
                          value={acctno}
                          onChange={event => setAcctno(event.target.value)}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>Amount Due</InputGroupText>
                        </InputGroupAddon>
                        <FormInput id="amtdue" placeholder="Amount Due" 
                          value={amtdue}
                          onChange={event => setAmtdue(event.target.value)}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>Due Date</InputGroupText>
                        </InputGroupAddon>
                        <FormInput id="duedate" placeholder="Due Date" 
                          value={duedate}
                          onChange={event => setDuedate(event.target.value)}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>GL Code</InputGroupText>
                        </InputGroupAddon>
                        <FormInput id="glcode" placeholder="Account Code" 
                          value={acctglcode}
                          onChange={event => setAcctglcode(event.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Button type="button"
                      onClick={() => {
                        // fetchData();
                        setClick(click + 1);
                        console.log(click);
                      }}
                    >Process Invoice</Button>                    
                  </Form>
                  {/* <FormValidation /> */}
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>

          {/* Files & Dropdowns */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Files & Dropdowns</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">
                  Custom File Upload
                </strong>
                <CustomFileUpload />

                <strong className="text-muted d-block mb-2">
                  Dropdown Input Groups
                </strong>
                <DropdownInputGroups />

                <strong className="text-muted d-block mb-2">
                  Custom Select
                </strong>
                <CustomSelect />
              </ListGroupItem>
            </ListGroup>
          </Card>

        </Col>
      </Row>
    </Container>
  </div>
  );  
}

export default ApproveInvoices;
