import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import PageTitle from "../components/common/PageTitle";
import Forms from "../components/components-overview/Forms";
import FormValidation from "../components/components-overview/FormValidation";
import Sliders from "../components/components-overview/Sliders";
import ProgressBars from "../components/components-overview/ProgressBars";
import ButtonGroups from "../components/components-overview/ButtonGroups";
import InputGroups from "../components/components-overview/InputGroups";
import SeamlessInputGroups from "../components/components-overview/SeamlessInputGroups";
import CustomFileUpload from "../components/components-overview/CustomFileUpload";
import DropdownInputGroups from "../components/components-overview/DropdownInputGroups";
import CustomSelect from "../components/components-overview/CustomSelect";
import Invoice from "../invoice/Invoice";
import InvoicePortal from "./InvoicePortal";
import '../invoice/select-search-style.css'
import SelectSearch from 'react-select-search';
import {checkVendorPdfType} from "../utils/Commons";
import SortTable from "../components/sortable/SortTable";

import "../assets/dealminder.css"

function AddNewInvoice(props) {
  const pgid = 'dd_77987F15';
  const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
  const columns = [
    { label: "Date Received", accessor: "bill_date" },
    { label: "Vendor", accessor: "vendor_name" },
    { label: "Invoice #", accessor: "vendor_inv_number" },
    { label: "File Name", accessor: "filename" },
    { label: "Amount", accessor: "totals" },
    { label: "Status", accessor: "status" },
  ];  
  const [lineItems, setLineItems] = useState([]);
  const [invHeader, setInvHeader] = useState({});
  const [refreshList, setRefreshList] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [portalOpen, setPortalOpen] = useState(false);
  const [vendorForUpload, setVendorForUpload] = useState('');
  const [pdfType, setPdfType] = useState('A');
  const [invForStatus, setInvForStatus] = useState('');
  const [deals, setDeals] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [click, setClick] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.0);
  const [externalWindow, setExternalWindow] = useState(null);
  const [vendorEntityId, setVendorEntityId] = useState('');
  const documentWrapperRef = React.useRef();
  const invoiceHatch = React.createRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fetchInvoices = async () => {
    setIsLoading(true);
    setErrorMsg('');
    try {
    let p = sessionStorage.getItem('ptid');
    const result = await axios(
      DD_API_HOST+'/ap_invoices?cid='+p+'&sid='+pgid,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
    } else {
      let lst_inv = result.data.invoices.map(function(x){
        return x;
        }
      );
      setInvoices(lst_inv);
      setLineItems([]);
      setInvHeader({});
      // console.log(deals);  
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  function strToDate(s) {
    let d = null;
    let error = null;
    if (s == undefined || s == '') {
      d = new Date();
      error = "Invalid Date Value, set to today's date";
    }
    else {
      d = new Date(s+'T00:00:00');
    }
    return [d, error];
  }

  const fetchInv = async (selected_inv) => {
    if (invoiceHatch.current.state.editing === true)
      if (!window.confirm("Editing Invoice. Load Anyway?")) {
        console.log('i aint loading');
        return;
      } else {
        invoiceHatch.current.overrideEditing(false);
      }

    setIsLoading(true);
    setErrorMsg('');
    let rt = sessionStorage.getItem('rtok');
    try {
    const result = await axios(
      DD_API_HOST+'/ap_pdf?ref=' + selected_inv.id 
        + '&iu=' + selected_inv.filename
        + '&rt=' + rt,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
      setPdfUrl('');
      setLineItems([]);
      setInvHeader({});
    } else {
      setPdfUrl(DD_API_HOST+'/invoice/'+selected_inv.filename+'?tok=4234234');
      setLineItems(result.data.line_items);
      // We have to do this until we figure out a better way
      checkVendorPdfType(selected_inv.vendor_entity_id, setPdfType);
      
      let bd = strToDate(selected_inv.bill_date);
      if (bd[1] != null) {
        selected_inv.notes = (selected_inv.notes == undefined) ? '':selected_inv.notes;
        selected_inv.notes += '\nInvoice Date : ' + bd[1];
      }
      let bdd = strToDate(selected_inv.bill_due_date);
      if (bdd[1] != null) {
        selected_inv.notes = (selected_inv.notes == undefined) ? '':selected_inv.notes;
        selected_inv.notes += '\nDue Date : ' + bdd[1];
      }
        
      setInvHeader({
        id: selected_inv.id,
        vendor: selected_inv.vendor_entity_id,
        vendor_name: selected_inv.vendor_name,
        property: selected_inv.unit_entity_id,
        property_addr: selected_inv.unit_address,
        wo: selected_inv.wo_entity_id,
        wo_number: selected_inv.wo_number,
        gl_account: selected_inv.gl_account,
        gl_account_name: selected_inv.gl_account_name,
        vendor_inv_number: selected_inv.vendor_inv_number,
        bill_date: bd[0],
        bill_due_date: bdd[0],
        notes: selected_inv.notes,
        new_notes: '',
        filename: selected_inv.filename
      });
      //console.log(pdfUrl);
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  function incrRefresh() {
    setRefreshList(refreshList+1);
  }
  
  function downloadPdf() {
    if (invHeader.filename === undefined) {
      alert("No invoice loaded yet");
      return
    }
    const FileDownload = require('js-file-download');
    fetch(DD_API_HOST+'/invoice/'+invHeader.filename+'?tok=4234234',
    { 
      method: "GET",
      headers: { "Content-Type": "application/json"},
    }).then(response => response.blob()).then(response => {
      FileDownload(response, invHeader.filename);
    });
  }

  function registerVendor() {
    if (vendorEntityId === '') {
      alert('Please copy the Entity ID from the URL address of the vendor page on PW')
      return
    } else {
      console.log(vendorEntityId);
    }
    let p = sessionStorage.getItem('ptid');
    fetch(DD_API_HOST+'/regvend/'+vendorEntityId+'?tok=4234234&cid='+p,
    { 
      method: "GET",
      headers: { "Content-Type": "application/json"},
    }).then(response => response.json()).then(response => {
      console.log(response);
      alert(response.msg);
      setVendorEntityId('');
    });
  }

  function openPortal() {
    if (invoiceHatch.current.state.invHeader.vendor != undefined)
      checkVendorPdfType(invoiceHatch.current.state.invHeader.vendor, setPdfType);

    let winhandle = null;
    if (externalWindow == null || 
        (externalWindow != null && externalWindow.closed)) {
      let f = '';
      if (invHeader.filename != undefined)
        f = invHeader.filename;
      winhandle = window.open('/pdf-viewer?ui='+f, '', 'width=800,height=768,left=200,top=200');
      setExternalWindow(winhandle);
    }
    // setPortalOpen(!portalOpen);
    console.log("portalOpen:"+portalOpen);
  }

  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  const changePageScale = useCallback(
    (offset) => setPageScale((prevPageScale) => (prevPageScale || 1) + offset),
    [],
  );

  const zoomOut = useCallback(() => changePageScale(-1.0), [changePageScale]);

  const zoomIn = useCallback(() => changePageScale(1.0), [changePageScale]);

  function handleVendorSearchChange(name, e) {
    console.log('handlevendorsearchchange:'+e);
    setVendorForUpload(e);
  }
  function handleInvSearchChange(e, o) {
    console.log('handleInvSearchChange:'+e, o.status);
    setInvForStatus(o.status);
  }

  function getVendorOptions(query) {
    console.log('getVendorOptions:'+query);
    if (query.length < 3) return;
    return new Promise((resolve, reject) => {
      let p = sessionStorage.getItem('ptid');
      fetch(DD_API_HOST+'/get_options?header=vendor&cid='+p+'&tok=dd_ap_5143133231&qry='+query)
            .then(response => response.json())
            .then(({ options }) => {
                console.log('vendors:'+options);
                resolve(options.map( (option, idx) => ({ value: option[0], name: option[1] })))
            })
            .catch(reject);
    });
  }

  function getInvNumOptions(query) {
    console.log('getInvNumOptions:'+query);
    if (query.length < 2) return;
    return new Promise((resolve, reject) => {
        let p = sessionStorage.getItem('ptid');
        fetch(DD_API_HOST+'/get_options?header=invnum&cid='+p+'&tok=dd_ap_5143133231&qry='+query)
            .then(response => response.json())
            .then(({ options }) => {
                console.log('invoices:'+options);
                resolve(options.map( (option, idx) => ({ value: option[0], name: option[1],
                  status: option[2] })))
            })
            .catch(reject);
    });
  }

  useEffect(() => {
    //fetchData();
    fetchInvoices();
  }, [refreshList]);

  return (
    <div>
    <Container fluid className="px-0">
      { errorMsg === '' ? (
        <span/>
      ) : (
        <Alert className="mb-0">
        <i className="fa fa-info mx-2"></i> {errorMsg}
        </Alert>
      )}
    </Container>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Import Invoices"
          subtitle="Account Payable Workflow"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="8" className="mb-4">
          <Invoice ref={invoiceHatch} header={invHeader} lis={lineItems}
            pgid={pgid}
            incrRefresh={incrRefresh} />
          {/* <Card className="mb-4">
          <div>
          </div>
          </Card> */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              {/* <h6 className="m-0">{productName}</h6> */}
            </CardHeader>
            <CardBody className="p-0 pb-3">
            {isLoading ? (
              <div className="is_loading_true">Searching ...</div>
            ) : (
              <SortTable columns={columns} data={invoices} 
              fetchInv={fetchInv}/>
              // <table className="table mb-0">
              //   <thead className="bg-light">
              //     <tr>
              //       <th scope="col" className="border-0">
              //         Date Received
              //       </th>
              //       <th scope="col" className="border-0">
              //         Vendor
              //       </th>
              //       <th scope="col" className="border-0">
              //         Invoice #
              //       </th>
              //       <th scope="col" className="border-0">
              //         File Name
              //       </th>
              //       <th scope="col" className="border-0">
              //         Amount
              //       </th>
              //       <th scope="col" className="border-0">
              //         Status
              //       </th>
              //     </tr>
              //   </thead>
              //   <tbody>
              //     {deals.map((item,i) => {
              //       return (
              //         <Fragment>
              //           <tr style={item.rejection_reason == 'other' ? trReject : trDefault} onClick={() => {
              //               fetchInv(item);
              //             }} key={item.id}>
              //             <td title={item.id}>{item.bill_date}</td>
              //             <td>{item.vendor_name}</td>
              //             <td>{item.vendor_inv_number}</td>
              //             <td className="wrap-anywhere">{item.filename}</td>
              //             <td>{item.totals}</td>
              //             <td>{item.status}</td>
              //           </tr>
              //         </Fragment>
              //       )
              //     })}
              //   </tbody>
              // </table>
            )}
            </CardBody>
          </Card>

        </Col>

        <Col lg="4" className="mb-4">
          <Card small className="mb-4">
            {/* The file should point to a URL */}
            {/*file="/inv1.pdf"*/}
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page 
                //width={documentWrapperRef.current && documentWrapperRef.current.getBoundingClientRect().width}
                scale={pageScale}
                width="300"
                pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
            <div>
                  <Button
                    size="sm"
                    disabled={pageScale <= 1}
                    onClick={zoomOut}
                    type="button"
                  >
                    Zoom Out
                  </Button>
                  <span>
                    &nbsp;Scale: {pageScale}x&nbsp;
                  </span>
                  <Button
                    size="sm"
                    disabled={pageScale >= 5.0}
                    onClick={zoomIn}
                    type="button"
                  >
                    Zoom In
                  </Button>
            </div>
            <Button onClick={openPortal}>
              {portalOpen ? 'Close the' : 'Open a'} separate Window
            </Button>
            {portalOpen && (
            <InvoicePortal>
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                options={pdfType == 'A' ? {disableFontFace: true}:{disableFontFace: false}}
              >
                <div>
                  <button
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    type="button"
                  >
                    Previous
                  </button>
                  <span>
                    {`Page ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`}
                  </span>
                  <button
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    type="button"
                  >
                    Next
                  </button>
                </div>              

                <Page 
                  //width={documentWrapperRef.current && documentWrapperRef.current.getBoundingClientRect().width}
                  width="1000"
                  pageNumber={pageNumber}
                  />
              </Document>
            </InvoicePortal>
            )}
          </Card>

          {/* Files & Dropdowns */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Upload Invoices</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">
                  Select Vendor
                </strong>
                <SelectSearch search value={vendorForUpload}
                    placeholder="No Vendor Selected"
                    onChange={(e) => handleVendorSearchChange('vendor', e)}
                    getOptions={getVendorOptions}
                    />
                <strong className="text-muted d-block mb-2 mt-2">
                  Select Files
                </strong>
                <CustomFileUpload vendor={vendorForUpload}
                  incrRefresh={incrRefresh}/>

                {/* <strong className="text-muted d-block mb-2">
                  Dropdown Input Groups
                </strong>
                <DropdownInputGroups />

                <strong className="text-muted d-block mb-2">
                  Custom Select
                </strong>
                <CustomSelect /> */}
              </ListGroupItem>
            </ListGroup>
          </Card>

          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Download PDF</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <Button onClick={downloadPdf}>
                  Download
                </Button>
              </ListGroupItem>
            </ListGroup>
          </Card>

          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Invoice Status</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">
                  Select Invoice Number
                </strong>
                <SelectSearch search
                    placeholder="No Invoice Number Selected"
                    onChange={(e,o) => handleInvSearchChange(e, o)}
                    getOptions={getInvNumOptions}
                    />
                <p className="mt-3">Invoice Status:&nbsp;<strong>{invForStatus}</strong></p>
              </ListGroupItem>
            </ListGroup>
          </Card>

          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Register New Vendor</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">
                  Vendor Entity ID
                </strong>
                <FormInput placeholder="Copy from URL"
                  value={vendorEntityId}
                  onChange={(e) => setVendorEntityId(e.target.value)} />
                <Button onClick={registerVendor}>
                  Register
                </Button>
                <p className="mt-3"><strong>{invForStatus}</strong></p>
              </ListGroupItem>
            </ListGroup>
          </Card>

        </Col>
      </Row>
    </Container>
  </div>
  );  
}

export default AddNewInvoice;
