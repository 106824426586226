import React, {useEffect, useState} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";

function EditUser(props) {
  const pgid = 'dd_74927TUI';
  const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState({id: 0});

  useEffect(()=> {
    if (props.info.id == 0) return;

    console.log('prop changes', props);
    setUserInfo(props.info);
  }, [props]);

  const changePwd = async () => {
    // alert('changine pwed you');
    let rt = sessionStorage.getItem('rtok');
    axios.post(DD_API_HOST+'/ap_chgp?sid='+pgid, {
      rtok: rt,
    })
    .then((response) => {
      console.log(response);
      if (response.data.msg != undefined)
        alert(response.data.msg);
      else if (response.data.err != undefined)
        alert(response.data.err);
    }, (error) => {
      console.log(error);
    });
  }

  const saveUser = async (e, o) => {
    // alert('changine pwed you');
    let rt = sessionStorage.getItem('rtok');
    let p = sessionStorage.getItem('ptid');
    let pallet = {
      rtok: rt,
      cid: p
    }
    let action = e.target.attributes.action.value;
    if (action == 'update') {
      pallet = Object.assign(userInfo, pallet);
    } else if (action == 'add') {
      if (userInfo.id != 0) {
        if (!window.confirm("Add New User with this info?")) {
          console.log('i aint loading');
          return;
        } 
      } else if (userInfo.username === undefined ||
          userInfo.username == "") {
        alert('Please enter complete User Info');
        return;
      }
      if (userInfo.role === undefined)
        userInfo.role = 'APENTRY';
      pallet = Object.assign(userInfo, pallet);
      console.log(pallet);
    } else if (action == 'del') {
      console.log('deactivatin');
      pallet.action = 'delete';
      pallet = Object.assign(userInfo, pallet);
    } else if (action == 'resetpwd') {
      console.log('resetting');
      pallet.action = action;
      pallet = Object.assign(userInfo, pallet);
    }

    axios.post(DD_API_HOST+'/save_user?sid='+pgid, pallet)
    .then((response) => {
      console.log(response);
      if (response.data.msg != undefined) {
        if (response.data.nonce != undefined)
          alert("New User password: "+ response.data.nonce +
            "\nThis will be the only time it is visible." +
            "\nSave as needed.");
        else
          alert("User Info Updated.");
        setInfoLoaded(false);
        setUserInfo({id:0,
          username:"",
          fullname:"",
          role:"APENTRY",
          invoice_threshold:"",
          new_notes:""});
      }
      else if (response.data.err != undefined)
        alert(response.data.err);
    }, (error) => {
      console.log(error);
    });

    return;
  }

  useEffect(() => {
      if (userInfo.username == '') {
        console.log('triggering refresh');
        props.incrRefresh();
      }
  }, [userInfo]);

  const handleFieldChange = (e) => {
    setInfoLoaded(true);
    let fieldId = e.target.attributes.id.value;
    let newUserInfo = {id: 0};
    if (fieldId == 'feUserName') {
      newUserInfo = {
        ...userInfo, username: e.target.value
      }
    }
    else if (fieldId == 'feName') {
      newUserInfo = {
        ...userInfo, fullname: e.target.value
      }
    }
    else if (fieldId == 'feRole') {
      newUserInfo = {
        ...userInfo, role: e.target.value
      }
    }
    else if (fieldId == 'feThres') {
      newUserInfo = {
        ...userInfo, invoice_threshold: e.target.value
      }
    }
    else if (fieldId == 'feNotes') {
      newUserInfo = {
        ...userInfo, new_notes: e.target.value
      }
    }
    setUserInfo(newUserInfo);
  }

  return (
  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">Edit User</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
            <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="feUserName">Username</label>
                  <FormInput
                    id="feUserName"
                    placeholder="Email"
                    value={userInfo.username}
                    onChange={handleFieldChange}
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="feName">Name</label>
                  <FormInput
                    id="feName"
                    placeholder="Name"
                    value={userInfo.fullname}
                    onChange={handleFieldChange}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="feRole">Role</label>
                  <FormSelect
                    id="feRole"
                    value={userInfo.role}
                    onChange={handleFieldChange}
                  >
                    <option value="APENTRY">AP Entry</option>
                    <option value="APMANAGER">AP Manager</option>
                    <option value="DUALMANAGER">Dual Manager</option>
                    <option value="MAINTMANAGER">Maint. Manager</option>
                    <option value="PROPMANAGER">Prop. Manager</option>
                    <option value="SUPERVISOR">Supervisor</option>
                    <option value="DIRECTOR">Director</option>
                  </FormSelect>
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="feThres">Inv. Threshold</label>
                  <FormInput
                    id="feThres"
                    placeholder="Largest Inv. Value"
                    value={userInfo.invoice_threshold}
                    onChange={handleFieldChange}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="feNotes">Notes</label>
                  {props.info.notes ? (
                    <p>{userInfo.notes}</p>
                    ):''}
                  <FormTextarea
                    id="feNotes"
                    value={userInfo.new_notes}
                    onChange={handleFieldChange}
                  />
                </Col>
                {/* Password */}
                <Col md="6" className="form-group">
                  <Row>
                    <Col md="4" className="form-group">
                    <Button
                    // style={{height: '30px', width : '100px'}}
                    disabled={!infoLoaded}
                    action="update"
                    size="md"
                    className="d-flex btn-blue ml-auto mr-auto mt-3"
                    onClick={saveUser}
                    >
                      Update
                    </Button>
                    </Col>
                    <Col md="4" className="form-group">
                    <Button
                      action="add"
                      size="md"
                      className="d-flex btn-blue ml-auto mr-auto mt-3"
                      onClick={saveUser}
                    >
                      Add New
                    </Button>
                    </Col>
                    <Col md="4" className="form-group">
                    <Button
                      action="del"
                      size="md"
                      className="d-flex btn-blue ml-auto mr-auto mt-3"
                      onClick={saveUser}
                    >
                      Deactivate
                    </Button>
                    </Col>
                  </Row>
                  <Button
                    action="resetpwd"
                    size="md"
                    className="d-flex btn-blue ml-auto mr-auto mt-3"
                    onClick={saveUser}
                  >
                    Reset Password
                  </Button>
                </Col>
              </Row>
              {/* <FormGroup>
                <label htmlFor="feAddress">Address</label>
                <FormInput
                  id="feAddress"
                  placeholder="Address"
                  value="1234 Main St."
                  onChange={() => {}}
                />
              </FormGroup> */}
              {/* <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormInput
                    id="feCity"
                    placeholder="City"
                    onChange={() => {}}
                  />
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="feInputState">State</label>
                  <FormSelect id="feInputState">
                    <option>Choose...</option>
                    <option>...</option>
                  </FormSelect>
                </Col>
                <Col md="2" className="form-group">
                  <label htmlFor="feZipCode">Zip</label>
                  <FormInput
                    id="feZipCode"
                    placeholder="Zip"
                    onChange={() => {}}
                  />
                </Col>
              </Row> */}
              {/* <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Description</label>
                  <FormTextarea id="feDescription" rows="5" />
                </Col>
              </Row>
              <Button theme="accent">Update Account</Button> */}
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  );
}

EditUser.propTypes = {
  info: PropTypes.object,
  incrRefresh: PropTypes.func
};

export default EditUser;
