import React, {useState} from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import Login from "./views/Login";
import useToken from "./useToken";

function App() {
  // Don't use Javascript memory to store token
  // const [token, setToken] = useState();

  // const token = getToken();
  const { token, setToken } = useToken();

  if(!token && (window.location.href.search('pm-approval') == -1 &&
                window.location.href.search('pub-scr') == -1)) {
    return <Login setToken={setToken} />
  }

  return (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </div>
  </Router>
  );
}

export default App;
