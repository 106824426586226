import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { Button, FormInput, Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";

function PubScreen() {
  let history = useHistory();
  const pgid = 'dd_74927DOC';
  const [newPwd, setNewPwd] = useState('');
  const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
  const { hash } = useParams();

  function handleNewPwd(e) {
    setNewPwd(e.target.value);
  }
  
  const submitNewPwd = async () => {
    axios.post(DD_API_HOST+'/ap_dochgp?sid='+pgid, {
      rtok: hash,
      np: newPwd
    })
    .then((response) => {
      console.log(response);
      if (response.data.msg != undefined)
        alert(response.data.msg);
      else if (response.data.err != undefined)
        alert(response.data.err);

      // WG: Always sync this with saveToken
      sessionStorage.removeItem('dd_ap_token');
      sessionStorage.removeItem('ptid');
      sessionStorage.removeItem('mrid');

      history.push('/');
      history.go(0);
      return null;
    }, (error) => {
      console.log(error);
    });
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Change Password" subtitle="User Account" md="12" className="ml-sm-auto mr-sm-auto" />
      </Row>
      <Row>
        <Col lg="12">
          <label>Please enter your new password:</label>
          <FormInput id="newPwd" 
            style={{width: "370px"}}
            type="password"
            onChange={(e) => handleNewPwd(e)} />
          <Button onClick={submitNewPwd}>Submit</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default PubScreen;
