import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import autobahn from "autobahn";
// import WS from 'reactjs-autobahn'

const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
const pgid = 'dd_77987X17';
var g_heartbeat_timeout_id = null;

//const client = new W3CWebSocket('wss://svp4.nextcoder.com/ws');
var connection = new autobahn.Connection({
  url: 'wss://svp4.nextcoder.com/ws',
  realm: 'dwdigest_realm_10'}
);

var heartbeat = function() {
  // console.log(">> sending heartbeat to server:", new Date());
  connection.session.publish("heartbeat_topic", ['1'], {}, {});
  g_heartbeat_timeout_id = setTimeout(heartbeat, 60000);
}              

const PdfViewer = (props) => {
  let init_pdf = props.location.search.slice(4);
  init_pdf = DD_API_HOST+'/invoice/'+init_pdf+'?tok=4234234'

  const [pdfUrl, setPdfUrl] = useState(init_pdf);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.0);
  const [externalWindow, setExternalWindow] = useState(null);
  const [socketState, setSocketState] = useState('');
  const [socketErrorState, setSocketErrorState] = useState('');

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  const ws_onopen = (session) => {
    console.log(">> WAMP: connection.onopen");
    function client_ws_handler(args) {
        console.log(args);
        let rt = sessionStorage.getItem('rtok');
        if(rt == args[0].sid) {
          // setPdfUrl(DD_API_HOST+'/invoice/'+args[0]['pdf_file']+'?tok=4234234');
          setPdfUrl(DD_API_HOST+'/invoice/'+args[0].pdf_file+'?tok=4234234');
        }
    }
    
    session.subscribe('dd_77987X17', client_ws_handler);
    // session.subscribe(comp_id, client_ws_handler);
    heartbeat();
  }

  useEffect(() => {
    if (!connection.isConnected) {
      connection.onopen = ws_onopen;
    
      connection.onclose = function (session) {
        console.log(">> WAMP: connection.onclose");
        try {                    
            connection.close();
        } catch(err) {
            console.log(">> WAMP: calling close results in excep:", err);
        }
        clearTimeout(g_heartbeat_timeout_id);
      }
      connection.open();
    }
    return function cleanup() {
      // alert('closing autobahn');
      connection.close();
    };

  }, []);

  return (
    <Document
      file={pdfUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      // options={pdfType == 'A' ? {disableFontFace: true}:{disableFontFace: false}}
    >
      <div>
        <button
          disabled={pageNumber <= 1}
          onClick={previousPage}
          type="button"
        >
          Previous
        </button>
        <span>
          {`Page ${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`}
        </span>
        <button
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          type="button"
        >
          Next
        </button>
      </div>              

      <Page 
        //width={documentWrapperRef.current && documentWrapperRef.current.getBoundingClientRect().width}
        width="1000"
        pageNumber={pageNumber}
        />
    </Document>

  )

}

export default PdfViewer;