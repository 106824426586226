import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";

function UserAccountDetails({title,fullname}) {
  const pgid = 'dd_74927UAD';
  const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;

  const changePwd = async () => {
    // alert('changine pwed you');
    let rt = sessionStorage.getItem('rtok');
    axios.post(DD_API_HOST+'/ap_chgp?sid='+pgid, {
      rtok: rt,
    })
    .then((response) => {
      console.log(response);
      if (response.data.msg != undefined)
        alert(response.data.msg);
      else if (response.data.err != undefined)
        alert(response.data.err);
    }, (error) => {
      console.log(error);
    });
  }

  return (
  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feFirstName">Name</label>
                  <FormInput
                    id="feFirstName"
                    placeholder="First Name"
                    value={fullname}
                    onChange={() => {}}
                  />
                </Col>
                {/* <Col md="6" className="form-group">
                  <label htmlFor="feLastName">Last Name</label>
                  <FormInput
                    id="feLastName"
                    placeholder="Last Name"
                    value="Brooks"
                    onChange={() => {}}
                  />
                </Col> */}
              </Row>
              <Row form>
                {/* Email */}
                <Col md="6" className="form-group">
                  <label htmlFor="feEmail">Email</label>
                  <FormInput
                    type="email"
                    id="feEmail"
                    placeholder="Email Address"
                    value="sierra@example.com"
                    onChange={() => {}}
                    autoComplete="email"
                  />
                </Col>
                {/* Password */}
                <Col md="6" className="form-group">
                  {/* <label htmlFor="fePassword">Password</label> */}
                  <Button
                    size="md"
                    className="d-flex btn-blue ml-auto mr-auto mt-3"
                    onClick={changePwd}
                  >
                    Change Password
                  </Button>
                </Col>
              </Row>
              {/* <FormGroup>
                <label htmlFor="feAddress">Address</label>
                <FormInput
                  id="feAddress"
                  placeholder="Address"
                  value="1234 Main St."
                  onChange={() => {}}
                />
              </FormGroup> */}
              {/* <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="feCity">City</label>
                  <FormInput
                    id="feCity"
                    placeholder="City"
                    onChange={() => {}}
                  />
                </Col>
                <Col md="4" className="form-group">
                  <label htmlFor="feInputState">State</label>
                  <FormSelect id="feInputState">
                    <option>Choose...</option>
                    <option>...</option>
                  </FormSelect>
                </Col>
                <Col md="2" className="form-group">
                  <label htmlFor="feZipCode">Zip</label>
                  <FormInput
                    id="feZipCode"
                    placeholder="Zip"
                    onChange={() => {}}
                  />
                </Col>
              </Row> */}
              {/* <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="feDescription">Description</label>
                  <FormTextarea id="feDescription" rows="5" />
                </Col>
              </Row>
              <Button theme="accent">Update Account</Button> */}
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
  );
}

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  fullname: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details",
  fullname: "Frodo Baggins"
};

export default UserAccountDetails;
