import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Forms from "../components/components-overview/Forms";
import FormValidation from "../components/components-overview/FormValidation";
import Sliders from "../components/components-overview/Sliders";
import ProgressBars from "../components/components-overview/ProgressBars";
import ButtonGroups from "../components/components-overview/ButtonGroups";
import InputGroups from "../components/components-overview/InputGroups";
import SeamlessInputGroups from "../components/components-overview/SeamlessInputGroups";
import CustomFileUpload from "../components/components-overview/CustomFileUpload";
import DropdownInputGroups from "../components/components-overview/DropdownInputGroups";
import CustomSelect from "../components/components-overview/CustomSelect";

import "../assets/dealminder.css"

function WmDeals(props) {
  const [wmsku, setWmsku] = useState('');
  const [productName, setProductName] = useState('');
  const [zip, setZip] = useState('');
  const [deals, setDeals] = useState([]);
  const [click, setClick] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const fetchData = async () => {
    if (wmsku === '') {
      console.log('ping');
      if (click > 0)
        setErrorMsg('Yg bener aja bro!');
      return;
    }

    setIsLoading(true);
    setErrorMsg('');
    try {
    const result = await axios(
      'http://kyoto.nextcoder.com:35442/getwmdeals?skus=' + wmsku
      +'&zip='+ zip,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
    } else {
      setDeals(result.data.deals);
      setProductName(result.data.item_title);
      console.log(deals);  
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [click]);

  return (
    <div>
    <Container fluid className="px-0">
      { errorMsg === '' ? (
        <span/>
      ) : (
        <Alert className="mb-0">
        <i className="fa fa-info mx-2"></i> {errorMsg}
        </Alert>
      )}
    </Container>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Walmart Deals"
          subtitle="Overview"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="8" className="mb-4">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Form Inputs</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Form className="wmdeals-form">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>SKU</InputGroupText>
                        </InputGroupAddon>
                        <FormInput id="wmsku" placeholder="Put SKU here" 
                          value={wmsku}
                          onChange={event => setWmsku(event.target.value)}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText>Zip</InputGroupText>
                        </InputGroupAddon>
                        <FormInput id="zip" placeholder="Put Zip here" 
                          value={zip}
                          onChange={event => setZip(event.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Button type="button"
                      onClick={() => {
                        // fetchData();
                        setClick(click + 1);
                        console.log(click);
                      }}
                    >Search Deals</Button>                    
                  </Form>
                  {/* <FormValidation /> */}
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">{productName}</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
            {isLoading ? (
              <div className="is_loading_true">Searching ...</div>
            ) : (
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Store Name
                    </th>
                    <th scope="col" className="border-0">
                      Address
                    </th>
                    <th scope="col" className="border-0">
                      Availability
                    </th>
                    <th scope="col" className="border-0">
                      Qty.
                    </th>
                    <th scope="col" className="border-0">
                      Price
                    </th>
                    <th scope="col" className="border-0">
                      Discount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deals.map((item,i) => {
                    return (
                      <Fragment>
                        <tr key={i}>
                          <td>{item.name}</td>
                          <td>{item.addr}</td>
                          <td>{item.avail}</td>
                          <td>{item.qty}</td>
                          <td>{item.price}</td>
                          <td>{item.discount}</td>
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            )}
            </CardBody>
          </Card>

        </Col>

        <Col lg="4" className="mb-4">
          {/* Sliders & Progress Bars */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Sliders & Progress Bars</h6>
            </CardHeader>
            <ListGroup flush>
              <ProgressBars />
              <Sliders />
            </ListGroup>
          </Card>

          {/* Groups */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Groups</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <Form>
                  <strong className="text-muted d-block mb-3">
                    Button Groups
                  </strong>
                  <ButtonGroups />

                  <strong className="text-muted d-block mb-2">
                    Input Groups
                  </strong>
                  <InputGroups />

                  <strong className="text-muted d-block mb-2">
                    Seamless Input Groups
                  </strong>
                  <SeamlessInputGroups />
                </Form>
              </ListGroupItem>
            </ListGroup>
          </Card>

          <Card small>
            {/* Files & Dropdowns */}
            <CardHeader className="border-bottom">
              <h6 className="m-0">Files & Dropdowns</h6>
            </CardHeader>

            <ListGroup flush>
              <ListGroupItem className="px-3">
                <strong className="text-muted d-block mb-2">
                  Custom File Upload
                </strong>
                <CustomFileUpload />

                <strong className="text-muted d-block mb-2">
                  Dropdown Input Groups
                </strong>
                <DropdownInputGroups />

                <strong className="text-muted d-block mb-2">
                  Custom Select
                </strong>
                <CustomSelect />
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
  );  
}

export default WmDeals;
