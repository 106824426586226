import React  from 'react';
import { useState } from "react";
import "./sortable.css"

const SortTableHead = ({ columns, handleSorting }) => {
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");
    const handleSortingChange = (accessor) => {
        const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };    
    return (
        <thead className="bg-light">
        <tr>
        {columns.map(({ label, accessor }) => {
            const cl = true
            ? sortField === accessor && order === "asc"
            ? "up"
            : sortField === accessor && order === "desc"
            ? "down"
            : "default"
            : "";
            return (
            <th
            key={accessor} onClick={() => handleSortingChange(accessor)}
            className={cl}
            >
            {label}
            </th>
            );
        })}

        </tr>
        </thead>
    );
};

export default SortTableHead;