import React, { Component } from 'react'
import './select-search-style.css'
import styles from './Invoice.module.scss'
import LineItemsMobile from './LineItemsMobile'
import uuidv4 from 'uuid/v4'
import { FormTextarea, FormSelect, Button, Modal, ModalBody, ModalHeader, FormInput } from "shards-react";
import SelectSearch from 'react-select-search';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class InvoiceMobile extends Component {
  DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
  locale = 'en-US'
  currency = 'USD'

  state = {
    editing: false,
    taxRate: 0.00,
    pgid: '',
    modalOpen: false,
    rejectReason:'duplicate',
    readonly: false,
    vend_option: [],
    prop_option: [],
    wo_option: [],
    gl_option: [],
    removed_items: [],
    invHeader: {
      id: 0,
      vendor: '',
      property: '',
      property_addr: '',
      wo_number: '',
      gl_account: '',
      gl_account_name: '',
      inv_number: '',
      bill_date: new Date(),
      bill_due_date: new Date(),
      notes: '',
      new_notes: ''
    },
    lineItems: [
      {
        id: 'initial',      // react-beautiful-dnd unique key
        name: '',
        description: '',
        gl_acct:'',
        quantity: 0,
        price: 0.00,
      },
    ]
  }

  modalToggle() {
    // if (this.state.editing === true)
    console.log(this.state.invHeader.vendor);
  
    if (this.state.invHeader.vendor != '')
      this.setState({modalOpen: !this.state.modalOpen});
  }

  componentDidMount() {
    this.modalToggle = this.modalToggle.bind(this);
    this.state.readonly = this.props.readonly;
  }

  componentDidUpdate() {
    if (this.state.editing == false) {
      // console.log('didupdate'+this.props.header.id);
      if (this.props.header.id !== undefined) {
        this.state.lineItems = this.props.lis;
        this.state.invHeader = this.props.header;
        this.state.new_notes = '';

        this.state.vend_option = [{name:this.state.invHeader.vendor_name,
            value:this.state.invHeader.vendor}];
        this.state.prop_option = [{name:this.state.invHeader.property_addr,
            value:this.state.invHeader.property}];
        this.state.wo_option = [{name:this.state.invHeader.wo_number,
            value:this.state.invHeader.wo}];
        this.state.gl_option = [{name:this.state.invHeader.gl_account_name,
            value:this.state.invHeader.gl_account}];
  
        this.state.pgid = this.props.pgid;
      }
      else {
        if (this.state.invHeader.id > 0) {
          console.log('emptyheader');
          this.setState({invHeader:{vendor: ""}});
        }
      }
    } else {
      console.log('editing === true');
      // if(window.confirm("Editing detected. Override?"))
      //   this.state.editing = false;
    }
  }

  handleInvoiceChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  overrideEditing = (ed) => {
    this.setState({editing: ed});
  }
  
  handleLineItemChange = (elementIndex) => (event) => {
    this.setState({editing: true});
    let lineItems = this.state.lineItems.map((item, i) => {
      if (elementIndex !== i) return item
      return {...item, [event.target.name]: event.target.value}
    })
    this.setState({lineItems})
  }

  handleAddLineItem = (event) => {
    this.setState({
      editing: true,
      // use optimistic uuid for drag drop; in a production app this could be a database id
      lineItems: this.state.lineItems.concat(
        [{ id: -1, name: '', description: '', quantity: 1, price: 0.00 }]
      )
    })
  }

  handleRemoveLineItem = (elementIndex, item_id) => (event) => {
    this.setState({
      editing: true,
      lineItems: this.state.lineItems.filter((item, i) => {
        return elementIndex !== i
      }),
      removed_items: this.state.removed_items.concat(item_id)
    })
  }

  handleReorderLineItems = (newLineItems) => {
    this.setState({
      editing: true,
      lineItems: newLineItems,
    })
  }

  handleFocusSelect = (event) => {
    event.target.select()
  }

  handleCreateButtonClick = () => {
    if (this.state.invHeader.vendor == '')
      return;
      
    this.setState({editing: false});
    console.log(this.state.lineItems);
    fetch(this.DD_API_HOST+'/ap_entry?cid='+this.props.cid+'&tok=dd_ap_5143133231', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        header: this.state.invHeader,
        lis: this.state.lineItems,
        rem: this.state.removed_items,
        pgid: this.state.pgid
      })
    })
      .then((data) => {
        data.json().then((data) => {
          //console.log('editing '+this.state.editing);
          alert(data.msg);
          this.setState({
            invHeader:{vendor: this.state.invHeader.vendor,
              vendor_name: this.state.invHeader.vendor_name,
              gl_account: this.state.invHeader.gl_account},
            lineItems:[]});
          this.props.incrRefresh();
        });
      });
  }

  selectRejectReason = (e) => {
    this.setState({rejectReason: e.target.value});
  }

  handleRejectButtonClick = () => {
    if (this.state.invHeader.vendor == '') {
      return;
    }

    this.setState({editing: false});
    console.log('rejectReason>>>'+this.state.rejectReason)
    fetch(this.DD_API_HOST+'/ap_reject?tok=dd_ap_5143133231', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        header: this.state.invHeader,
        lis: this.state.lineItems,
        rem: this.state.removed_items,
        pgid: this.state.pgid,
        rid: this.state.rejectReason
      })
    })
      .then((data) => {
        data.json().then((data) => {
          // console.log(data);
          this.setState({invHeader:{vendor: ""},
            lineItems:[]});
          this.state.modalOpen = false;
          this.props.incrRefresh();
          alert(data.msg);
        });
      });
  }

  handleSelectSearchChange = (name, e) => {
    // console.log('handleselectsearchchange:'+e);
    this.setState({editing: true});
    let nih = { ...this.state.invHeader};
    if (name === 'vendor') 
      nih.vendor = e;
    else if (name === 'property') 
      nih.property = e;
    else if (name === 'wo') {
      nih.wo = e;
      fetch(this.DD_API_HOST+'/wo_prop?tok=dd_ap_5143133231', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          wo: e,
          pgid: this.state.pgid,
        })
      })
        .then((data) => {
          data.json().then((data) => {
            if (data.error != '') {
              alert('The property is not found for this Work Order');
            } else {
              nih.property = data.prop.unit_entity_id;
              nih.property_addr = data.prop.unit_address;
              console.log(nih);
              this.setState({invHeader: nih});
            }
          });
        });  
    }
    // else if (name === 'gl') {
    //   nih.gl_account = e;
    //   if (this.state.lineItems.length > 0) {
    //     let lis = [ ...this.state.lineItems];
    //     lis.forEach(li => {
    //       li.gl_acct = e;
    //     })
    //   }
    // } 
    
    // this.setState({invHeader: nih}, () => {console.log(this.state.invHeader)});
  }

  handleNewNotesChange = (e) => {
    console.log('handlenewnoteschange:'+e.target.value);
    this.setState({editing: true});
    let nih = { ...this.state.invHeader};
    nih.new_notes = e.target.value;
    
    this.setState({invHeader: nih});
  }

  handleDateChange = (name, date) => {
    console.log('handledatechange:'+date);
    this.setState({editing: true});
    let nih = { ...this.state.invHeader};
    if (name == 'sale') 
      nih.bill_date = date;
    else if (name == 'due') 
      nih.bill_due_date = date;
    
    this.setState({invHeader: nih});
  }

  handleInvChange = (value) => {
    console.log('handleInvChange:'+value.target.value);
    this.setState({editing: true});
    let nih = { ...this.state.invHeader};
    nih.vendor_inv_number = value.target.value;
    
    this.setState({invHeader: nih});
  }

  getVendorOptions = (query) => {
    console.log('getVendorOptions:'+query);
    if (query.length < 3) return;
    return new Promise((resolve, reject) => {
        fetch(this.DD_API_HOST+'/get_options?header=vendor&tok=dd_ap_5143133231&qry='+query)
            .then(response => response.json())
            .then(({ options }) => {
                console.log('vendors:'+options);
                resolve(options.map( (option, idx) => ({ value: option[0], name: option[1] })))
            })
            .catch(reject);
    });
  }

  getPropOptions = (query) => {
    console.log('getPropOptions:'+query);
    if (query.length < 3) return;
    return new Promise((resolve, reject) => {
        fetch(this.DD_API_HOST+'/get_options?header=prop&tok=dd_ap_5143133231&qry='+query)
            .then(response => response.json())
            .then(({ options }) => {
              console.log('units:'+options);
              resolve(options.map( (option, idx) => ({ value: option[0], name: option[1] })))
            })
            .catch(reject);
    });
  }

  getWoOptions = (query) => {
    console.log('getWoOptions:'+query);
    if (query.length < 3) return;
    return new Promise((resolve, reject) => {
        fetch(this.DD_API_HOST+'/get_options?header=wo&tok=dd_ap_5143133231&qry='+query)
            .then(response => response.json())
            .then(({ options }) => {
                console.log('options:'+options);
                resolve(options.map( (option, idx) => ({ value: option[0], name: option[1] })))
            })
            .catch(reject);
    });
  }

  getGlOptions = (query) => {
    console.log('getGlOptions:'+query);
    if (query.length < 3) return;
    return new Promise((resolve, reject) => {
        fetch(this.DD_API_HOST+'/get_options?header=gl&tok=dd_ap_5143133231&qry='+query)
            .then(response => response.json())
            .then(({ options }) => {
                console.log('options:'+options);
                resolve(options.map( (option, idx) => ({ value: option[0], name: option[0]+'-'+option[1] })))
            })
            .catch(reject);
    });
  }

  formatCurrency = (amount) => {
    return (new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount))
  }

  calcTaxAmount = (c) => {
    return c * (this.state.taxRate / 100)
  }

  calcLineItemsTotal = () => {
    return this.state.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price)), 0)
  }

  calcTaxTotal = () => {
    return this.calcLineItemsTotal() * (this.state.taxRate / 100)
  }

  calcGrandTotal = () => {
    return this.calcLineItemsTotal() + this.calcTaxTotal()
  }

  render = () => {
    const {modalOpen} = this.state;
    return (

      <div className={styles.invoiceMobile}>
        {/* <div className={styles.brand}>
          <img src="https://via.placeholder.com/150x50.png?text=logo" alt="Logo" className={styles.logo} />
        </div> */}
        <div className={styles.addresses}>
        <div>
          {this.state.readonly == true ? 
            <div className={`${styles.valueTable} ${styles.to}`}>
            <div className={styles.row}>
              <div className={styles.label}>Vendor</div>
              <div className={styles.value}>{this.state.invHeader.vendor_name}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Property</div>
              <div className={styles.value}>{this.state.invHeader.property_addr}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Work Order</div>
              <div className={styles.value}>{this.state.invHeader.wo_number}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>GL Account</div>
              <div className={styles.value}>{this.state.invHeader.gl_account}</div>
            </div>
            </div>
            :
            <div>
            <div className={`${styles.valueTable} ${styles.to}`}>
            <div className={styles.row}>
              <div className={styles.label}>Vendor</div>
              <div className={styles.value}>{this.state.invHeader.vendor_name}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Property</div>
              <div className={styles.value}>{this.state.invHeader.property_addr}</div>
            </div>
            </div>
            {/* <SelectSearch search getOptions={this.getVendorOptions} value={this.state.invHeader.vendor}
              placeholder="Select vendor"
              onChange={(e) => this.handleSelectSearchChange('vendor', e)}
              options={this.state.vend_option}
              />
            <SelectSearch search getOptions={this.getPropOptions} value={this.state.invHeader.property}
              placeholder="Select Property"
              onChange={(e) => this.handleSelectSearchChange('property', e)}
              options={this.state.prop_option}
              /> */}
            <SelectSearch search getOptions={this.getWoOptions} value={this.state.invHeader.wo}
                placeholder="Select Work Order"
                onChange={(e) => this.handleSelectSearchChange('wo', e)}
                options={this.state.wo_option}
              />
            {/* <SelectSearch search getOptions={this.getGlOptions} value={this.state.invHeader.gl_account}
                placeholder="Select Default GL Account"
                onChange={(e) => this.handleSelectSearchChange('gl', e)}
                options={this.state.gl_option}
              /> */}
            </div>
          }

          </div>
          <div>
          {this.state.readonly == true ? 
            <div className={`${styles.valueTable} ${styles.to}`}>
            <div className={styles.row}>
              <div className={styles.label}>Invoice #</div>
              <div className={styles.value}>{this.state.invHeader.vendor_inv_number}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Date</div>
              <div className={`${styles.value} ${styles.date}`}>{
                  this.state.invHeader.bill_date != undefined ? 
                  this.state.invHeader.bill_date.toLocaleDateString("en-US")
                  :
                  ''}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Due Date</div>
              <div className={`${styles.value} ${styles.date}`}>{
                  this.state.invHeader.bill_due_date != undefined ? 
                  this.state.invHeader.bill_due_date.toLocaleDateString("en-US")
                  :
                  ''}</div>
            </div>
            </div>
            :
            <div className={`${styles.valueTable} ${styles.to}`}>
              <div className={styles.row}>
                <div className={styles.label}>Invoice #</div>
                {/* <div className={styles.value}>{this.state.invHeader.vendor_inv_number}</div> */}
                <FormInput onChange={this.handleInvChange} value={this.state.invHeader.vendor_inv_number} />
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Date</div>
                <div className={`${styles.value} ${styles.date}`}>
                  <DatePicker selected={this.state.invHeader.bill_date}
                    onChange={date => this.handleDateChange('sale', date)}
                    withPortal
                    />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Due Date</div>
                <div className={`${styles.value} ${styles.date}`}>
                  <DatePicker selected={this.state.invHeader.bill_due_date}
                    onChange={date => this.handleDateChange('due', date)}
                    withPortal
                    />
                </div>
              </div>
            </div>
          }
          </div>
        </div>
        <h2>&nbsp;</h2>

          <LineItemsMobile
            items={this.state.lineItems}
            currencyFormatter={this.formatCurrency}
            addHandler={this.handleAddLineItem}
            changeHandler={this.handleLineItemChange}
            focusHandler={this.handleFocusSelect}
            deleteHandler={this.handleRemoveLineItem}
            reorderHandler={this.handleReorderLineItems}
          /> 

        <div className={styles.totalContainer}>
          <form>
            <div className={styles.valueTable}>
              { this.state.invHeader.notes != null ? 
                <div dangerouslySetInnerHTML={{ __html: this.state.invHeader.notes.replace(/\n/g, '<br>') }} />
                : <div></div>
              }
              {/* <div className={styles.row}>
                <div className={styles.value}>{this.state.invHeader.notes}</div>
              </div> */}
              <FormTextarea placeholde="Enter Notes here" onChange={this.handleNewNotesChange} />
              {/* <div className={styles.row}>
                <div className={styles.value}><input name="taxRate" type="number" step="0.01" value={this.state.taxRate} onChange={this.handleInvoiceChange} onFocus={this.handleFocusSelect} /></div>
              </div> */}
            </div>
          </form>
          {/* <div></div> */}
          <form>
            <div className={styles.valueTable}>
              {/* <div className={styles.row}>
                <div className={styles.label}>Subtotal</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcLineItemsTotal())}</div>
              </div>
              <div className={styles.row}>
                <div className={styles.label}>Tax ({this.state.taxRate}%)</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcTaxTotal())}</div>
              </div> */}
              <div className={styles.row}>
                <div className={styles.label}>Total Due</div>
                <div className={`${styles.value} ${styles.currency}`}>{this.formatCurrency(this.calcGrandTotal())}</div>
              </div>
              <div className={styles.pay}>
                <Button className={styles.payNow} onClick={this.handleCreateButtonClick}>
                    Create</Button>
                {/* <Button className={styles.payNow} onClick={this.modalToggle}>
                    {this.state.pgid == 'dd_77987F15' ?
                    'Deactivate Invoice':'Reject Invoice'}</Button> */}
              </div>
            </div>
          </form>
        </div>

        <Modal open={modalOpen} toggle={this.modalToggle}>
          <ModalHeader>Specify Reason</ModalHeader>
          <ModalBody>
          <FormSelect onChange={this.selectRejectReason}>
            <option selected value="duplicate">Duplicate Invoice</option>
            <option value="other">Other reason (See Notes)</option>
          </FormSelect>
          <Button className={styles.payNow} onClick={this.handleRejectButtonClick}>
            {this.state.pgid == 'dd_77987F15' ?
              'Deactivate':'Reject'}
          </Button>
          </ModalBody>
        </Modal>

        {/* <div className={styles.footer}>
          <div className={styles.comments}>
            <h4>Notes</h4>
            <p>This page is for manually creating Invoices.</p>
          </div>
          <div className={styles.closing}>
            <div>Thank-you for your business</div>
          </div>
        </div> */}

      </div>

    )
  }

}

InvoiceMobile.defaultProps = {
  readonly: false
};

export default InvoiceMobile
