import React from "react";
import {
  Card
} from "shards-react";

const ImagePanel = ({ userDetails }) => (
  <Card small className="mb-4 pt-3">
    <img alt="DW Digest" src="https://images.unsplash.com/photo-1498409785966-ab341407de6e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80&txt=MIN%28D%29ING%20YOUR%20DATA&txt-size=62&txt-color=f0f0f0&txt-align=middle,center&txt-font=Futura%20Condensed%20Medium"/>
  </Card>
);

export default ImagePanel;
