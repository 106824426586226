import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('dd_ap_token');
    const userToken = JSON.parse(tokenString);
    return userToken && userToken.token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    // WG: Always sync this with logout
    sessionStorage.setItem('dd_ap_token', JSON.stringify(userToken));
    sessionStorage.setItem('ptid', userToken.ptid);
    sessionStorage.setItem('namae', userToken.namae);
    sessionStorage.setItem('rtok', userToken.token);
    sessionStorage.setItem('mrid', userToken.token.slice(-2));
    // console.log(userToken);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}