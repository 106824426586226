import React, { Fragment, useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormSelect,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import PageTitle from "../components/common/PageTitle";
import Forms from "../components/components-overview/Forms";
import FormValidation from "../components/components-overview/FormValidation";
import Sliders from "../components/components-overview/Sliders";
import ProgressBars from "../components/components-overview/ProgressBars";
import ButtonGroups from "../components/components-overview/ButtonGroups";
import InputGroups from "../components/components-overview/InputGroups";
import SeamlessInputGroups from "../components/components-overview/SeamlessInputGroups";
import CustomFileUpload from "../components/components-overview/CustomFileUpload";
import DropdownInputGroups from "../components/components-overview/DropdownInputGroups";
import CustomSelect from "../components/components-overview/CustomSelect";
import Invoice from "../invoice/Invoice"
import InvoicePortal from "./InvoicePortal";
import {checkVendorPdfType} from "../utils/Commons";

import "../assets/dealminder.css"
import EditUser from "../components/user-profile-lite/EditUser";

function UserManagement(props) {
  const pgid = 'dd_76351TUM';
  const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
  const [lineItems, setLineItems] = useState([]);
  const [invHeader, setInvHeader] = useState({vendor: ""});
  const [userFields, setUserFields] = useState({id: 0});
  const [refreshList, setRefreshList] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [portalOpen, setPortalOpen] = useState(false);
  const [pdfType, setPdfType] = useState('A');
  const [editingUser, setEditingUser] = useState(false);
  const [amtdue, setAmtdue] = useState('');
  const [duedate, setDuedate] = useState('');
  const [acctglcode, setAcctglcode] = useState('');
  const [users, setUsers] = useState([]);
  const [click, setClick] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.0);
  const documentWrapperRef = React.useRef();
  const invoiceHatch = React.createRef();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fetchUsers = async () => {
    setIsLoading(true);
    setErrorMsg('');
    try {
    let p = sessionStorage.getItem('ptid');
    let rt = sessionStorage.getItem('rtok');
    const result = await axios(
      DD_API_HOST+'/ap_users?cid='+p+'&sid='+pgid+'&rt='+rt,
    );
    console.log(result);
    if (result.data.error) {
      // alert(result.data.error);
      setErrorMsg(result.data.error);
    } else {
      setUsers(result.data.users);
      // setLineItems([]);
      // setInvHeader({vendor: ""});
    }
    setIsLoading(false);
    } catch(error) {
      console.log(error);
    }
  };

  const loadUser = async (selected_inv) => {
    if (editingUser === true)
      if (!window.confirm("Editing User. Replace?")) {
        console.log('i aint loading');
        return;
      }

    setIsLoading(true);
    setErrorMsg('');
    setUserFields({
      id: selected_inv.id,
      username: selected_inv.username,
      role: selected_inv.role,
      fullname: selected_inv.fullname,
      active: selected_inv.active,
      invoice_threshold: selected_inv.invoice_threshold,
      notes: selected_inv.notes,
      new_notes: '',
    });
    setIsLoading(false);
    window.scrollTo(0,0);
  };

  function incrRefresh() {
    setRefreshList(refreshList+1);
    setUserFields({id:0});
  }

  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  const changePageScale = useCallback(
    (offset) => setPageScale((prevPageScale) => (prevPageScale || 1) + offset),
    [],
  );

  const zoomOut = useCallback(() => changePageScale(-1.0), [changePageScale]);

  const zoomIn = useCallback(() => changePageScale(1.0), [changePageScale]);

  useEffect(() => {
    console.log('fetching users');
    fetchUsers();
  }, [refreshList]);

  return (
    <div>
    <Container fluid className="px-0">
      { errorMsg === '' ? (
        <span/>
      ) : (
        <Alert className="mb-0">
        <i className="fa fa-info mx-2"></i> {errorMsg}
        </Alert>
      )}
    </Container>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Manage Users"
          subtitle="Account Payable Workflow"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="8" className="mb-4">
          <EditUser ref={invoiceHatch} info={userFields} 
            pgid={pgid} readonly={true}
            incrRefresh={incrRefresh}/>
          {/* <Card className="mb-4">
          <div>
          </div>
          </Card> */}
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              {/* <h6 className="m-0">{productName}</h6> */}
            </CardHeader>
            <CardBody className="p-0 pb-3">
            {isLoading ? (
              <div className="is_loading_true">Searching ...</div>
            ) : (
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Username
                    </th>
                    <th scope="col" className="border-0">
                      Name
                    </th>
                    <th scope="col" className="border-0">
                      Role
                    </th>
                    <th scope="col" className="border-0">
                      Active
                    </th>
                    <th scope="col" className="border-0">
                      Inv. Threshold
                    </th>
                    {/* <th scope="col" className="border-0">
                      Notes
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {users.map((item,i) => {
                    return (
                      <Fragment>
                        <tr onClick={() => {
                            loadUser(item);
                          }} key={item.id}>
                          <td title={item.id}>{item.username}</td>
                          <td>{item.fullname}</td>
                          <td>{item.role}</td>
                          <td>{item.active.toString()}</td>
                          <td>{item.invoice_threshold}</td>
                          {/* <td>{item.notes}</td> */}
                        </tr>
                      </Fragment>
                    )
                  })}
                </tbody>
              </table>
            )}
            </CardBody>
          </Card>

        </Col>

        <Col lg="4" className="mb-4">
          <Card small className="mb-4">
            {/* The file should point to a URL */}
            {/*file="/inv1.pdf"*/}
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page 
                //width={documentWrapperRef.current && documentWrapperRef.current.getBoundingClientRect().width}
                scale={pageScale}
                width="300"
                pageNumber={pageNumber} />
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
            <div>
                  <Button
                    size="sm"
                    disabled={pageScale <= 1}
                    onClick={zoomOut}
                    type="button"
                  >
                    Zoom Out
                  </Button>
                  <span>
                    &nbsp;Scale: {pageScale}x&nbsp;
                  </span>
                  <Button
                    size="sm"
                    disabled={pageScale >= 5.0}
                    onClick={zoomIn}
                    type="button"
                  >
                    Zoom In
                  </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
  );  
}

export default UserManagement;
