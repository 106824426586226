import React  from 'react';
const SortTableBody = ({ tableData, columns, fetchInv }) => {
    const trReject = {
        backgroundColor: 'antiquewhite',
    };
    
    const trDefault = {
        backgroundColor: 'white',
    };    
    return (
        <tbody>
        {tableData.map((data) => {
        let reject_other = trDefault;
        if (data.hasOwnProperty('rejection_reason'))
            if (data['rejection_reason'] == 'other')
                reject_other = trReject;
        return (
        <tr onClick={() => {fetchInv(data);}} 
            key={data.id}
            style={reject_other}>
            {columns.map(({ accessor }) => {
            const tData = data[accessor] ? data[accessor] : "——";
            return <td>{tData}</td>;
            })}
        </tr>
        );
        })}
        </tbody>
    );
};

export default SortTableBody;