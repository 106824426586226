import React  from 'react';
import { useState } from "react";
import SortTableBody from "./SortTableBody";
import SortTableHead from "./SortTableHead";

const SortTable = ({columns, data, fetchInv}) => {
    const [tableData, setTableData] = useState(data);

    function isDataEmpty() {
        console.log(tableData.length);
        if (tableData.length <= 0)
            return true;
        else
            return false;
    }
    const handleSorting = (sortField, sortOrder) => {
        console.log(sortField, sortOrder)
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                numeric: true,
                }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTableData(sorted);
        }   
    };
    return (
    <>
    {isDataEmpty() ? <span>No invoices to process at this time.</span> :
    <table className="sorttable">
    <caption></caption>
    <SortTableHead columns={columns} handleSorting={handleSorting} />
    <SortTableBody columns={columns} tableData={tableData}
        fetchInv={fetchInv} />
    </table>
    }
    </>
    );
};

export default SortTable;