import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
import { BlankLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import UserManagement from "./views/UserManagement";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import WmDeals from "./views/WmDeals";
import ApproveInvoices from "./views/ApproveInvoices";
import ApApproval from "./views/ApApproval";
import MgrApproval from "./views/MgrApproval";
import SupervisorApproval from "./views/SupervisorApproval";
import DirectorApproval from "./views/DirectorApproval";
import PmApproval from "./views/PmApproval";
import PubScreen from "./views/PubScreen";
import AddNewInvoice from "./views/AddNewInvoice";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Logout from "./views/Logout";
import PdfViewer from "./views/PdfViewer";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/blog-overview" />
  },
  {
    path: "/logout",
    layout: DefaultLayout,
    component: Logout
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/user-management",
    layout: DefaultLayout,
    component: UserManagement
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/ap-invoices",
    layout: DefaultLayout,
    component: ApproveInvoices
  },
  {
    path: "/ap-approval",
    layout: DefaultLayout,
    component: ApApproval
  },
  {
    path: "/mgr-approval",
    layout: DefaultLayout,
    component: MgrApproval
  },
  {
    path: "/spvsr-approval",
    layout: DefaultLayout,
    component: SupervisorApproval
  },
  {
    path: "/director-approval",
    layout: DefaultLayout,
    component: DirectorApproval
  },
  {
    path: "/new-invoice",
    layout: DefaultLayout,
    component: AddNewInvoice
  },
  {
    path: "/pm-approval/:iid",
    layout: BlankLayout,
    component: PmApproval
  },
  {
    path: "/pub-scr1/:hash",
    layout: BlankLayout,
    component: PubScreen // change password
  },
  {
    path: "/pdf-viewer",
    layout: BlankLayout,
    component: PdfViewer
  },
  {
    path: "/wm-deals",
    layout: DefaultLayout,
    component: WmDeals
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  }
];
