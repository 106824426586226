import React, { useState } from "react";
import axios from "axios";

function CustomFileUpload(props) {
  const DD_API_HOST = process.env.REACT_APP_DD_API_HOST;
  const [status, setStatus] = useState('');

  function handleFileSelected(e) {
    // console.log(e.target.files);

    var formData = new FormData();
    for (const key of Object.keys(e.target.files)) {
        formData.append('file', e.target.files[key])
    }
    if (props.vendor !== '')
      formData.append('veid', props.vendor);
    let p = sessionStorage.getItem('ptid');
    axios.post(DD_API_HOST+"/ap_upload?cid="+p, formData, {
    }).then(res => {
        console.log(res.data);
        setStatus(res.data.msg);
        props.incrRefresh();
    }).catch(function (error) {
      console.log(error);
    })

  }

  return (
  <span>
    <div className="custom-file mb-3">
      <input multiple type="file" onChange={handleFileSelected} className="custom-file-input" id="customFile2" />
      <label className="custom-file-label" htmlFor="customFile2">
        Choose file...
      </label>
    </div>
    <div>{status}</div>
  </span>
  );
}

export default CustomFileUpload;
